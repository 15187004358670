import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import "./Dashboard.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import ConfirmationModel from "../ConfirmModal/ConfirmationModel";

function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [dateEndFilter, setEndDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    orderList();
  }, []);
  useEffect(() => {
    applyFilters();
  }, [dateFilter, statusFilter]);
  const orderList = () => {
    setOrders([]);
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list-order",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        if (data.data.result) {
          setOrders(data.data.list);
        } else {
          console.log("Data fetch failed");
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  };
  const totalOrderAmount = Math.floor(
    orders.reduce((sum, order) => sum + order.od_amount, 0)
  );
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  const handleDateChange = (e) => {
    setDateFilter(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDateFilter(e.target.value);
  };
  const applyFilters = () => {
    setOrders([]);
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list-order",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        first_date: dateFilter,
        last_date: dateEndFilter,
        order_status: statusFilter,
      },
    })
      .then((data) => {
        if (data.data.result) {
          setOrders(data.data.list);
        } else {
          console.log("Data fetch failed");
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  };

  const downloadpdf = () => {
    setLoader(true);
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/download/order-history",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        format: "pdf",
        first_date: dateFilter,
        last_date: dateEndFilter,
        order_status: statusFilter,
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          onButtonClick(data.data.pdf);
        } else {
          setLoader(false);
          console.log("not working");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching teams:", err);
      });
  };
  const onButtonClick = async (data) => {
    try {
      const response = await axios.get(data, {
        responseType: "blob",
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);

      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Registeration.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error downloading PDF:", error);
    }
  };
  const handleStatusChange = (orderId, newStatus) => {
    console.log(orderId, newStatus, "newStatus");

    const updatedOrders = orders.map((order) =>
      order.id === orderId ? { ...order, od_delivery_status: newStatus } : order
    );
    setOrders(updatedOrders);
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/update/delivery-status",
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      data: {
        order_id: orderId,
        status: newStatus,
      },
    })
      .then((response) => {
        if (response.data.result) {
          toast.success("Delivery status updated successfully!");
          orderList();
        } else {
          toast.error("Failed to update delivery status.");
        }
      })
      .catch((error) => {
        console.error("Error updating delivery status:", error);
        toast.error("Error updating delivery status.");
      });
  };
  const handlePaymentChange = (orderId, newStatus) => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/update/payment-status",
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      data: {
        order_id: orderId,
        status: newStatus,
      },
    })
      .then((response) => {
        if (response.data.result) {
          toast.success("Delivery status updated successfully!");
          orderList();
        } else {
          toast.error("Failed to update Payment status.");
        }
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        toast.error("Error updating payment status.");
      });
  };
  const deleteOrder = (response) => {
    console.log(selectedTeamId, "selectedTeamId");

    if (response.isConfirm) {
      axios({
        url: "https://lunarsenterprises.com:6013/kdpetroleum/delete",
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        data: {
          od_id: selectedTeamId,
        },
      })
        .then((response) => {
          if (response.data.result) {
            toast.success(response.data.message);
            orderList();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error updating payment status:", error);
          toast.error("Error updating payment status.");
        });
    }
    setShowConfirmModal(false);
  };
  const onDeleteConfirm = (orderId) => {
    setSelectedTeamId(orderId);
    setShowConfirmModal(true);
  };

  // Paginate orders
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);
  console.log(currentOrders, "currentOrders");

  const totalPages = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="dashboard">
        <h2>Dashboard</h2>

        <Row className="mb-4" style={{ justifyContent: "space-around" }}>
          <Col xs={3} sm={2} md={1} lg={3}>
            <Card style={{ textAlign: "center" }}>
              <Card.Body>
                <h5>Sales</h5>
                <h3>₹{totalOrderAmount}</h3>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={3} sm={2} md={1} lg={3}>
            <Card style={{ textAlign: "center" }}>
              <Card.Body>
                <h5>Orders</h5>
                <h3>{orders.length}</h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                {/* Title */}
                <div className="transaction-history-container">
                  <h2 className="transaction-history-title">Orders History</h2>

                  {/* Export Button */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <label>Date Filter:</label>
                      <input
                        type="date"
                        className="form-control"
                        value={dateFilter}
                        onChange={handleDateChange}
                      />
                      <label>End Date Filter:</label>
                      <input
                        type="date"
                        className="form-control"
                        value={dateEndFilter}
                        onChange={handleEndDateChange}
                      />
                      <label>Status Filter:</label>
                      <select
                        className="form-select"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="Confirmed">Confirmed</option>
                        <option value="Pending">Pending</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </div>
                    <div>
                      <button
                        style={{
                          border: "none",
                          borderRadius: "10px",
                          padding: "10px",
                          // marginTop: "-20px",
                          marginBottom: "10px",
                          backgroundColor: "#295a29c9",
                          color: "white",
                        }}
                        disabled={loader}
                        onClick={() => downloadpdf()}
                      >
                        {loader ? (
                          <ThreeDots
                            visible
                            height="20"
                            width="20"
                            color="#0c0773"
                          />
                        ) : (
                          "Export PDF"
                        )}
                      </button>
                    </div>
                  </div>

                  {/* Orders Table */}
                  <table
                    className="transaction-history-table table table-bordered"
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th style={{ padding: "10px" }}>User Name</th>
                        <th style={{ padding: "10px" }}>Date & Time</th>
                        <th style={{ padding: "10px" }}>Product</th>
                        <th style={{ padding: "10px" }}>Amount</th>
                        <th style={{ padding: "10px" }}>Payment Type</th>
                        <th style={{ padding: "10px" }}>Delivery Address</th>
                        <th style={{ padding: "10px" }}>Phone Number</th>
                        <th style={{ padding: "10px" }}>Payment Status</th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Order Status
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentOrders.length > 0 ? (
                        currentOrders.map((transaction, index) => (
                          <tr key={index}>
                            <td style={{ padding: "10px" }}>
                              {transaction.user_name}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {formatDate(transaction.od_created_at)}
                            </td>
                            <td>
                              <table border="0" style={{ width: "100%" }}>
                                {transaction.OrderProducts.map((item, idx) => (
                                  <tr key={idx}>
                                    <td>{item.name}</td>
                                    <td>Qty: {item.quantity}</td>
                                    {item.size ? (
                                      <td>Size: {item.size}</td>
                                    ) : null}
                                  </tr>
                                ))}
                              </table>
                            </td>
                            <td style={{ padding: "10px" }}>
                              ₹{parseFloat(transaction.od_amount).toFixed(2)}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {transaction.od_payment_method}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {transaction.billing_address.replace(
                                /,\d{10}$/,
                                ""
                              )}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {transaction.billing_address.split(",").pop()}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {transaction.od_payment_status === "paid" ? (
                                <span
                                  className={`transaction-history-status ${
                                    transaction.od_payment_status === "paid"
                                      ? "bg-success"
                                      : transaction.od_payment_status ===
                                        "Pending"
                                      ? "bg-warning"
                                      : "bg-danger"
                                  }`}
                                  style={{
                                    padding: "5px 10px",
                                    color: "white",
                                    borderRadius: "5px",
                                    display: "inline-block",
                                  }}
                                >
                                  {transaction.od_payment_status}
                                </span>
                              ) : (
                                <select
                                  value={transaction.od_payment_status}
                                  onChange={(e) =>
                                    handlePaymentChange(
                                      transaction.od_id,
                                      e.target.value
                                    )
                                  }
                                  className="form-select"
                                  style={{
                                    padding: "5px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    width: "100%",
                                  }}
                                >
                                  <option value={transaction.od_payment_status}>
                                    {transaction.od_payment_status}
                                  </option>
                                  <option value="paid">paid</option>
                                </select>
                              )}
                            </td>

                            {/* Action */}
                            <td style={{ textAlign: "center" }}>
                              <select
                                value={transaction.od_delivery_status}
                                onChange={(e) =>
                                  handleStatusChange(
                                    transaction.od_id,
                                    e.target.value
                                  )
                                }
                                className="form-select"
                                style={{
                                  padding: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  width: "100%",
                                }}
                              >
                                <option value="Confirmed">Confirmed</option>
                                <option value="Pending">Pending</option>
                                <option value="Delivered">Delivered</option>
                              </select>
                            </td>
                            {/* {delete icon} */}
                            <td style={{ textAlign: "center" }}>
                              <FaTrash
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() =>
                                  onDeleteConfirm(transaction.od_id)
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="9"
                            style={{ textAlign: "center", padding: "20px" }}
                          >
                            No orders found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <div className="pagination-controls">
                      {Array.from({ length: totalPages }, (_, i) => (
                        <button
                          key={i + 1}
                          className={`page-btn ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {showConfirmModal && (
          <ConfirmationModel
            id={selectedTeamId}
            callback={deleteOrder}
            onClose={() => setShowConfirmModal(false)}
          />
        )}
      </div>
    </>
  );
}

export default Dashboard;
